<template>
  <div class="about">
    <headerNav></headerNav>
    <el-carousel :interval="5000" arrow="always" height="750px">
      <el-carousel-item v-for="item in bannerList" :key="item">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel>
    <!-- 移动端时黄块 -->
    <div class="phoneIntroduction">
      <div class="phoneIntroductionTitle">燕京雍阳：为孩子的未来扬帆起航</div>
      <div class="phoneIntroductionContent">
        在童年这片美丽的土地上，有一个特别的地方—燕京雍阳。<br />
        我们是一所不仅教育，更是关爱的幼儿园，<br />
        因为深知，每个孩子都是独一无二的宝藏。
      </div>
    </div>
    <div class="phoneAboutus">关于我们</div>

    <div class="aboutUs">
      <img
        src="https://yyyey.oss-cn-beijing.aliyuncs.com/yeyAboutUsTitlePc.png"
        alt=""
        class="aboutImg"
      />
      <div class="aboutBox"> 
        <div class="aboutTop1">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/mutou.png"
            alt=""
          />
        </div>
        <div class="aboutTop2">
          <img
            src="
    https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/panpa.png"
            alt=""
          />
        </div>
        <div class="aboutTop3">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/taowa.png"
            alt=""
          />
        </div>
      </div>

      <div class="aboutBox">
        <div class="leftImg">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/guoqi.png"
            alt=""
          />
        </div>
        <div class="contentCenter">
          <div class="centerTop">
            <div class="wenziCenter">
              <div class="centerTitle">燕京雍阳：为孩子的未来扬帆起航</div>
              <p>在童年这片美丽的土地上，有一个特别的地方——燕京雍阳。</p>
              <p>我们是一所不仅教育，更是关爱的幼儿园，</p>
              <p>因为深知，每个孩子都是独一无二的宝藏。</p>
            </div>
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/canzhuo.png"
              alt=""
            />
          </div>
          <div class="centerBtm">
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/tiepiwu.png"
              alt=""
            />
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/sanlunche.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div class="shiming">
      <div class="smTitle">我们的使命</div>
      <p class="phone">培养未来的领袖和创新者</p>
      <p class="phone">
        我们坚信，教育是塑造孩子未来的关键，而关爱和专业是教育宗旨。
      </p>
      <div class="pcSmBtm">
        <div class="smBtm">
          <div class="smBox smBox1">
            <p>
              在燕京雍阳，爱是我们教育的源泉。我们不仅仅传授知识，更注重培养孩子的品格和情感智慧。
            </p>
            <p>以爱为本</p>
          </div>
          <div class="smBox smBox2">
            <p>专业团队</p>
            <p>
              我们拥有一支充满激情和专业性的教育团队。每位教师都经过精心筛选和专业培训，致力于为孩子提供最佳的教育。
            </p>
          </div>
          <div class="smBox smBox3">
            <p>
              每个孩子都是独一无二的，我们注重了解他们的个性和需求，为他们提供个性化的教育。
            </p>
            <p>个性化关怀</p>
          </div>
          <div class="smBox smBox4">
            <p>安全和温馨</p>
            <p>
              孩子的安全和幸福是我们最大的责任。为孩子们提供一个温馨、舒适、安全的学习环境。
            </p>
          </div>
        </div>
      </div>
      <!--  以爱为本移动端 -->
      <div class="smBtmPhone">
        <div class="smBox smBox1">
          <p class="phoneText">
            在燕京雍阳，爱是我们教育的源泉。我们不仅仅传授知识，更注重培养孩子的品格和情感智慧。
          </p>
          <p class="phoneTitle">以爱为本</p>
        </div>
        <div class="smBox smBox2">
          <p>专业团队</p>
          <p>
            我们拥有一支充满激情和专业性的教育团队。每位教师都经过精心筛选和专业培训，致力于为孩子提供最佳的教育。
          </p>
        </div>
        <div class="smBox smBox4">
          <p>安全和温馨</p>
          <p>
            孩子的安全和幸福是我们最大的责任。为孩子们提供一个温馨、舒适、安全的学习环境。
          </p>
        </div>
        <div class="smBox smBox3">
          <p>
            每个孩子都是独一无二的，我们注重了解他们的个性和需求，为他们提供个性化的教育。
          </p>
          <p>个性化关怀</p>
        </div>
      </div>
      <div class="weilai">
        <div class="wlBox">
          <div class="wlTitle">
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/weilaizhanwang.png"
              alt=""
            />
            <span>未来展望</span>
          </div>

          <div class="wlContent">
            <p>燕京雍阳将继续努力，不断创新，</p>
            <p>为孩子提供更多机会和更好的教育。</p>
            <p>我们坚信，每个孩子都有无限的潜力，</p>
            <p>而我们的使命就是帮助他们充分发挥这些潜力，</p>
            <p>扬帆起航，创造美好的未来。</p>
          </div>
        </div>
        <div class="wlBox jzBox">
          <div class="jzTitle">
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/jinmihezuo.png"
              alt=""
            />
            <span>与家长紧密合作</span>
          </div>

          <div class="jzContent">
            <p>在燕京雍阳，</p>
            <p>视家长为孩子教育的重要伙伴。</p>
            <p>积极与家长合作，</p>
            <p>共同制定孩子的成长计划，确保每个孩子都得到最好的关怀和教育。</p>
          </div>
        </div>
      </div>
    </div>

    <div class="fazhan">
      <div class="fzTitle">发展历程</div>
      <p class="fazhanP">
        燕京雍阳自创建以来，一直致力于提供高质量、专业化的教育。我们的发展历程充满着坚实的专业性和扎实的教育经验。
      </p>

      <div class="development">
        <div class="developBox">
          <div class="deveItem">
            <div class="deveTop">初创时期</div>
            <div class="lineYellow"></div>
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
              class="devetopImg huanImg"
              alt=""
            />
            <div class="deveBtmText">
              2020年，怀着对孩子未来的热爱和责任感，筹办了燕京雍阳幼儿园。初衷是为孩子们打造一个多元文化融合、注重全面发展的教育环境，满足每个孩子的独特需求。
            </div>
          </div>
          <div class="deveItem">
            <div class="devetopText">
              2021年，燕京雍阳逐渐壮大，致力于培养出色的教育团队、精心打磨教学课程，以及创新的教学方
              法，以确保教育达到最高标准。
            </div>
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/lv.png"
              class="devetopImg"
              alt=""
            />
            <div class="lineGreen"></div>
            <div class="deveBtm">不断发展</div>
          </div>
          <div class="deveItem">
            <div class="deveTop">教育创新</div>
            <div class="lineYellow"></div>
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
              class="devetopImg huanImg"
              alt=""
            />
            <div class="deveBtmText">
              2021年9月，我们迈出了新的一步，正式开园招生。燕京雍阳幼儿园逐渐形成了独特的教育理念和文化氛围，注重培养孩子们的创造力、探索问题和解决问题的能力，同时也更关注孩子们的社交能力和情感发展。
            </div>
          </div>
          <div class="deveItem">
            <div class="devetopText">
              如今，燕京雍阳拥有一支充满激情和专业性的教育团队。我们的教师都接受过专业培训，具备丰富的教育背景和经验，致力于为每个孩子提供最佳的教育。
            </div>
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/lv.png"
              class="devetopImg"
              alt=""
            />
            <div class="lineGreen"></div>
            <div class="deveBtm">专业团队</div>
          </div>
          <div class="deveItem">
            <div class="deveTop">未来展望</div>
            <div class="lineYellow"></div>
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
              class="devetopImg huanImg"
              alt=""
            />
            <div class="deveBtmText">
              燕京雍阳将继续努力，不断创新。为孩子提供更多机会和更好的教育。我们坚信，每个孩子都有无限的潜力，而我们的使命就是帮助孩子充分发挥这些潜力，扬帆起航，创造美好的未来。
            </div>
          </div>
        </div>
      </div>

      <div class="sansuo">
        <p>三所学府联袂打造，为孩子的未来扬帆起航！</p>
        <p>
          北外附校朝阳双语学校、天津燕京雍阳高级中学、人大附中朝阳分校东坝学校
          <br />
          共同肩负着培养未来领袖和国际人才的崇高使命。
        </p>
        <div class="sansuoBg">
          <div v-if="SchoolTextShow == 'one'">
            <p>北外附校朝阳双语学校：</p>
            <p>
              成立于2019年，位于北京市朝阳区，致力于培养具备全球竞争力的跨文化青年。学校提供国际幼儿园课程，通过注重学术和社交技能的综合发展，为学生提供了优先获职录用的机会，为未来的职业生涯打下坚实基础。
            </p>
          </div>
          <div v-if="SchoolTextShow == 'two'">
            <p>天津燕京雍阳高级中学：</p>
            <p>
              坐落于京津中心点的武清区龙凤新城，依托北京校区的优质资源，立志成为“市内一流，国内知名”的学府。学校秉承全面、系统、科学的教育理念，不仅为学生提供卓越的教育，还作为武清区政府的重点项目，服务着区域的发展和乡村振兴计划。
            </p>
          </div>
          <div v-if="SchoolTextShow == 'three'">
            <p>人大附中朝阳分校东坝学校：</p>
            <p>
              作为人大附中的成员校，人朝分东坝校区向东坝地区延伸，实行“五统一”的力学模式，提供多领域的课程，包括体育与健康、科学与生活、数学与逻辑、文学与社会、艺术与美学等。学校还开设了浸入式英语课程，由外教老师全英文授课，全面提升学生的综合素养。
            </p>
          </div>

          <div class="pcSchoolOne" @click="toSchoolText('three')"></div>
          <div class="pcSchoolTwo" @click="toSchoolText('two')"></div>
          <div class="pcSchoolThree" @click="toSchoolText('one')"></div>
        </div>
      </div>

      <div class="phoneDevelopment">
        <div class="developBox">
          <div class="developmentTwo">
            <div class="developBox">
              <div class="deveItem" @click="toSwitchTitle(1)">
                <div class="deveTop">初创</div>
                <div class="lineYellow"></div>
                <img
                  src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
                  class="devetopImg huanImg"
                  alt=""
                  v-if="deveIndex == 1"
                />
              </div>
              <div class="deveItem" @click="toSwitchTitle(2)">
                <div class="deveTop">发展</div>
                <div class="lineGreen"></div>
                <img
                  src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/lv.png"
                  class="devetopImg huanImg"
                  alt=""
                  v-if="deveIndex == 2"
                />
              </div>
              <div class="deveItem" @click="toSwitchTitle(3)">
                <div class="deveTop">创新</div>
                <div class="lineYellow"></div>
                <img
                  src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
                  class="devetopImg huanImg"
                  alt=""
                  v-if="deveIndex == 3"
                />
              </div>
              <div class="deveItem" @click="toSwitchTitle(4)">
                <div class="deveTop">专业</div>
                <div class="lineGreen"></div>
                <img
                  src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/lv.png"
                  class="devetopImg huanImg"
                  alt=""
                  v-if="deveIndex == 4"
                />
              </div>
              <div class="deveItem" @click="toSwitchTitle(5)">
                <div class="deveTop">未来</div>
                <div class="lineYellow"></div>
                <img
                  src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
                  class="devetopImg huanImg"
                  alt=""
                  v-if="deveIndex == 5"
                />
              </div>
            </div>
          </div>
          <div class="deveBtmText" v-if="deveIndex == 1">
            <div class="titleDeve">初创时期</div>
            <p>
              2020年，怀着对孩子未来的热爱和责任感，筹办了燕京雍阳幼儿园。初衷是为孩子们打造一个多元文化融合、注重全面发展的教育环境，满足每个孩子的独特需求。
            </p>
          </div>
          <div class="deveBtmText" v-if="deveIndex == 2">
            <div class="titleDeve">不断发展</div>
            <p>
              2021年，燕京雍阳逐渐壮大，致力于培养出色的教育团队、精心打磨教学课程，以及创新的教学方
              法，以确保教育达到最高标准。
            </p>
          </div>
          <div class="deveBtmText" v-if="deveIndex == 3">
            <div class="titleDeve">教育创新</div>
            <p>
              2021年9月，我们迈出了新的一步，正式开园招生。燕京雍阳幼儿园逐渐形成了独特的教育理念和文化氛围，注重培养孩子们的创造力、探索问题和解决问题的能力，同时也更关注孩子们的社交能力和情感发展。
            </p>
          </div>
          <div class="deveBtmText" v-if="deveIndex == 4">
            <div class="titleDeve">专业团队</div>
            <p>
              如今，燕京雍阳拥有一支充满激情和专业性的教育团队。我们的教师都接受过专业培训，具备丰富的教育背景和经验，致力于为每个孩子提供最佳的教育。
            </p>
          </div>
          <div class="deveBtmText" v-if="deveIndex == 5">
            <div class="titleDeve">未来展望</div>
            <p>
              燕京雍阳将继续努力，不断创新。为孩子提供更多机会和更好的教育。我们坚信，每个孩子都有无限的潜力，而我们的使命就是帮助孩子充分发挥这些潜力，扬帆起航，创造美好的未来。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端三所中学 -->
    <div class="sansuoPhone">
      <p>
        三所学府联袂打造， <br />
        为孩子的未来扬帆起航！
      </p>
      <p>
        北外附校朝阳双语学校、<br />
        天津燕京雍阳高级中学、<br />
        人大附中朝阳分校东坝学校 <br />
        共同肩负着培养未来领袖和国际人才的崇高使命。
      </p>
      <div class="phoneSchoolImg">
        <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/phone111.png"
          alt=""
        />
        <div class="phoneSchoolOne" @click="toSchoolText('one')"></div>
        <div class="phoneSchoolTwo" @click="toSchoolText('two')"></div>
        <div class="phoneSchoolThree" @click="toSchoolText('three')"></div>
      </div>
      <div class="sansuoGreen" v-if="SchoolTextShow == 'one'">
        <p>北外附校朝阳双语学校：</p>
        <p>
          成立于2019年，位于北京市朝阳区，致力于培养具备全球竞争力的跨文化青年。学校提供国际幼儿园课程，通过注重学术和社交技能的综合发展，为学生提供了优先获职录用的机会，为未来的职业生涯打下坚实基础。
        </p>
      </div>
      <div class="sansuoGreen" v-if="SchoolTextShow == 'three'">
        <p>天津燕京雍阳高级中学：</p>
        <p>
          坐落于京津中心点的武清区龙凤新城，依托北京校区的优质资源，立志成为“市内一流，国内知名”的学府。学校秉承全面、系统、科学的教育理念，不仅为学生提供卓越的教育，还作为武清区政府的重点项目，服务着区域的发展和乡村振兴计划。
        </p>
      </div>
      <div class="sansuoGreen" v-if="SchoolTextShow == 'two'">
        <p>人大附中朝阳分校东坝学校：</p>
        <p>
          作为人大附中的成员校，人朝分东坝校区向东坝地区延伸，实行“五统一”的力学模式，提供多领域的课程，包括体育与健康、科学与生活、数学与逻辑、文学与社会、艺术与美学等。学校还开设了浸入式英语课程，由外教老师全英文授课，全面提升学生的综合素养。
        </p>
      </div>
    </div>

    <div class="rongyu">
      <div class="fzTitle">燕京雍阳的荣誉与成就</div>
      <p class="fazhanP">
        荣誉墙是对幼儿园卓越表现的真实见证，一个闪耀着光芒的地方，记录了我们不断追求卓越的旅程
      </p>

      <div class="zhuoyu">
        <div class="zyLeft">
          <div class="zyLeTitle">卓越教育奖项</div>
          <p>燕京雍阳荣获多项卓越教育奖项</p>
        </div>
        <div class="pcRy">
          <div class="zyRight">
            <div class="zyRiTitle">国际认可:</div>
            <p>
              作为国际幼儿园和学校指导机构的合作伙伴，燕京雍阳备受认可。他们的专业访谈和指导，为我们提供了全球教育最新的理念和实践。
            </p>
            <div class="zyRiTitle">家长满意度达99%：</div>
            <p>
              家长是最重要的合作伙伴，他们的满意度是我们不懈努力的动力。匿名满意度调查显示，99%的家长对燕京雍阳的教育和关爱表示满意。
            </p>
            <div class="zyRiTitle">业界评价:</div>
            <p>
              燕京雍阳是一所备受业界推崇的专业幼儿园。我们的教育理念、教学方法和关爱服务都得到了业内的高度评价。
            </p>
          </div>
        </div>

        <div class="PhoneRy">
          <div class="zyRight">
            <div class="zyRiTitle">教育创新奖：</div>
            <p>
              我们一直秉承创新教育的理念，<br />为孩子们提供前沿的教育方式和资源。
            </p>
            <div class="zyRiTitle">家长满意度奖:</div>
            <p>
              家长的满意是我们最大的动力，<br />他们的认可是我们最珍贵的财富。
            </p>
            <div class="zyRiTitle">幼儿园质量认证：</div>
            <p>
              我们的教育质量经过专业认证，<br />确保每个孩子都得到最好的教育。
            </p>
          </div>
        </div>
      </div>
      <div class="pcRy">
        <div class="xueshu">
          <div class="zyRight xueRight">
            <div class="zyRiTitle xueTitle">学术成绩：</div>
            <p>我们的学生在各种学术竞赛中屡次获奖，展现了卓越的学术能力。</p>
            <div class="zyRiTitle xueTitle">艺术表现：</div>
            <p>孩子们的艺术作品在各种比赛和展览中备受好评，</p>
            <p>展示了他们的创造力和才华。</p>
            <div class="zyRiTitle xueTitle">体育成就：</div>
            <p>我们的学生在体育比赛中勇敢拼搏，赢得了各种奖项，</p>
            <p>培养了身体素质和团队合作精神。</p>
          </div>
          <div class="zyLeft xueLeft">
            <div class="zyLeTitle xueLeTitle">孩子的成就</div>
            <p>燕京雍阳的孩子们 也在各个领域取得了卓越成就</p>
          </div>
        </div>
      </div>

      <div class="PhoneRy">
        <div class="xueshu">
          <div class="zyLeft xueLeft">
            <div class="zyLeTitle xueLeTitle">孩子的成就</div>
            <p>燕京雍阳的孩子们 也在各个领域取得了卓越成就</p>
          </div>
          <div class="zyRight xueRight">
            <div class="zyRiTitle xueTitle">学术成绩：</div>
            <p>
              我们的学生在各种学术竞赛中屡次获奖，<br />展现了卓越的学术能力。
            </p>
            <div class="zyRiTitle xueTitle">艺术表现：</div>
            <p>孩子们的艺术作品在各种比赛和展览中备受好评，</p>
            <p>展示了他们的创造力和才华。</p>
            <div class="zyRiTitle xueTitle">体育成就：</div>
            <p>我们的学生在体育比赛中勇敢拼搏，赢得了各种奖项，</p>
            <p>培养了身体素质和团队合作精神。</p>
          </div>
        </div>
      </div>

      <div class="ganxie">
        <div class="ganxieContent">
          <!--  -->
          <p>荣誉是对燕京雍阳不断努力追求卓越的肯定，也是未来前进的动力。</p>
          <p>
            在燕京雍阳，我们将继续以孩子的未来为使命，为每个孩子提供最优质的教育和关怀。
          </p>
          <!-- <p>我们将继续努力，为每个孩子创造更多的机会和更好的未来。</p> -->
        </div>
      </div>
    </div>

    <div class="empty"></div>
    <footerBtm></footerBtm>
  </div>
</template>

<script>
import headerNav from "@/components/nav/nav";
import footerBtm from "@/components/footer";
export default {
  name: "about",
  components: { headerNav, footerBtm },
  data() {
    return {
      bannerList: [],
      deveIndex: 1,
      SchoolTextShow: "one",
    };
  },
  mounted() {
    window.scrollTo(0, 0);

    this.api.banner({ remark1: 4 }).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].img = JSON.parse(res.data[i].img);
      }
      this.bannerList = res.data;
    });
  },
  methods: {
    toSchoolText(e) {
      this.SchoolTextShow = e;
    },
    toSwitchTitle(e) {
      this.deveIndex = e;
    },
  },
};
</script>
<style scoped>
.aboutUs {
  width: 100%;
}

.aboutImg {
  width: 727px;
  height: 140px;
  display: block;
  margin: 30px auto;
}

.aboutBox {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.aboutTop1 {
  width: 621px;
  height: 400px;
}
.aboutTop2 {
  width: 544px;
  height: 400px;
}
.aboutTop3 {
  width: 715px;
  height: 400px;
}
img {
  width: 100%;
  height: 100%;
}

.leftImg {
  width: 410px;
  height: 820px;
}

.contentCenter {
  width: 1490px;
  height: 820px;
  margin-left: 20px;
}

.centerTop {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-between;
}
.centerTop img {
  width: 420px;
  height: 400px;
}
.wenziCenter {
  width: 1050px;
  height: 400px;
  background: #ffe500;
}
.wenziCenter p {
  width: 870px;
  font-size: 32px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #252525;
  margin-left: 133px;
  line-height: 64px;
}
.centerTitle {
  font-size: 60px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
}
.centerBtm {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.centerBtm img:first-child {
  width: 540px;
  height: 400px;
}
.centerBtm img:last-child {
  width: 930px;
  height: 400px;
}

.shiming {
  width: 1280px;
  height: 300px;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/shiMingKuang.png");
  background-size: 100% 100%;
  margin: 30px auto;
}
.shiming .phone {
  font-size: 32px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #975b00;
  line-height: 64px;
  text-align: center;
}

.smTitle {
  font-size: 60px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  text-align: center;
  padding-top: 40px;
  margin-bottom: 30px;
}

.pcSmBtm {
  display: block;
}
.smBtm {
  width: 100%;
  height: 368px;
  display: flex;
  justify-content: space-between;
  margin: 50px auto;
  margin-top: 80px;
}
.smBtmPhone {
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-top: 20px;
  display: none;
}

.smBox {
  width: 300px;
  height: 368px;
  background-size: 100% 100%;
}
.smBox1 {
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/yiaiweiben.png");
}
.smBox2 {
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/zhuanyetuandui.png");
}
.smBox3 {
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/gexinhuaguanhuai.png");
}
.smBox4 {
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/anquanhewenxin.png");
}

.smBox1 p:first-child,
.smBox3 p:first-child {
  width: 252px;
  height: 200px;
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  margin: 35px auto;
  line-height: 50px;
  text-align: left;
}
.smBox1 p:last-child,
.smBox3 p:last-child {
  font-size: 40px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  text-align: center;
  margin-top: 55px;
}
.smBox2 p:first-child,
.smBox4 p:first-child {
  font-size: 40px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 35px;
}
.smBox2 p:last-child,
.smBox4 p:last-child {
  width: 253px;
  height: 250px;
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  text-align: left;
  margin: 0 auto;
}

.weilai {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.wlBox {
  width: 620px;
  height: 475px;
  background: #e3f0ff;
  border-radius: 40px 40px 40px 40px;
}
.jzBox {
  background: #e0ffe9;
}
.wlTitle {
  width: 517px;
  margin: 0 auto;
  margin-top: 78px;
  position: relative;
}

.wlTitle img {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 133px;
  transform: translateY(-50%);
}
.jzTitle {
  width: 517px;
  margin: 0 auto;
  margin-top: 78px;
  position: relative;
}

.jzTitle img {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 80px;
  transform: translateY(-50%);
}

.wlTitle span {
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0075ff;
  position: absolute;
  right: 134px;
  top: 50%;
  transform: translateY(-50%);
}
.jzTitle span {
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #00af7b;
  position: absolute;
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
}

.wlContent {
  width: 517px;
  margin: 40px auto;
  margin-top: 180px;
  /* background: yellowgreen; */
}
.wlContent p {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0075ff;
  line-height: 48px;
}

.jzContent {
  width: 517px;
  margin: 40px auto;
  margin-top: 180px;
  /* background: yellowgreen; */
}
.jzContent p {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #00af7b;
  line-height: 48px;
}

.fazhan {
  width: 100%;
  height: 874px;
  margin-top: 1000px;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/fazhanlicheng.png");
  background-size: 100% 100%;
  /* background: yellowgreen; */
}
.fzTitle {
  font-size: 60px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  text-align: center;
  padding-top: 130px;
}
.fazhanP {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  margin: 40px auto;
  text-align: center;
}

.developBox {
  width: 1280px;
  display: flex;
  margin: 0 auto;
  /* background: red; */
}

.deveItem {
  width: 256px;
}

.deveTop {
  font-size: 28px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #25ae85;
  text-align: center;
  margin-top: 154px;
}

.devetopText,
.deveBtmText {
  width: 100%;
  height: 128px;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #252525;
  margin: 20px 0;
}

.devetopImg {
  width: 28px;
  height: 28px;
  margin: 0 144px 20px 144px;
}
.huanImg {
  margin: 20px 144px;
}

.lineYellow {
  width: 100%;
  height: 20px;
  background: #ffe500;
  margin-top: 30px;
}
.lineGreen {
  width: 100%;
  height: 20px;
  background: #25ae85;
}

.deveBtm {
  font-size: 28px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #25ae85;
  text-align: center;
  margin-top: 30px;
}

.rongyu {
  width: 1280px;
  margin: 0px auto;
  /* margin-top: -100px; */
  margin-top: 1200px;
}

.zhuoyu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
}
.xueshu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
}
.zyLeft {
  width: 440px;
  height: 435px;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/rongyuchengjiuOne.png");
  background-size: 100% 100%;
}
.xueLeft {
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/rongyuchengjiuTwo.png");
}
.zyLeft p {
  width: 192px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #975b00;
  line-height: 48px;
  margin-left: 124px;
  text-align: center;
}
.xueLeft p {
  color: #00885f;
}

.zyLeTitle {
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #975b00;
  margin-top: 132px;
  margin-left: 110px;
  margin-bottom: 30px;
}
.xueLeTitle {
  color: #00885f;
}
.zyRight {
  width: 744px;
  height: 387px;
}
.zyRiTitle {
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  text-align: center;
  font-weight: bold;
}
.xueTitle {
  color: #00885f;
}
.zyRight p {
  width: 744px;
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  color: #975b00;
  line-height: 64px;
}

.xueRight p {
  color: #00885f;
  text-align: center;
}
.ganxie {
  width: 1280px;
  height: 370px;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/rongyuchengjiuThree.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 150px;
}
.ganxieContent {
  width: 1000px;
  margin: 0 auto;
  padding-top: 80px;
}
.ganxieContent p {
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  line-height: 64px;
  text-align: center;
}

.empty {
  margin-bottom: 150px;
}
/* 移动端bnaner下黄块 */
.phoneIntroduction {
  display: none;
  text-align: center;
  padding: 13px 30px;
  box-sizing: border-box;
  background-color: #ffe500;
}
.phoneIntroduction .phoneIntroductionTitle {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #252525;
  margin-bottom: 10px;
}
.phoneIntroduction .phoneIntroductionContent {
  font-size: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #252525;
  line-height: 25px;
}
.phoneAboutus {
  display: none;
  width: 100%;
  height: 106px;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/phoneAboutus/phoneAboutusTitle.png");
  background-size: 100% 100%;
  text-align: center;
  line-height: 106px;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #25ae85;
}
.development {
  display: block;
}
.phoneDevelopment {
  display: none;
}

/* 荣誉 */
.PhoneRy {
  display: none;
}
.pcRy {
  display: block;
}
.sansuoPhone {
  display: none;
}
.sansuo {
  width: 85%;
  height: 400px;
  margin: 40px auto;
  display: block;
}
.sansuo p {
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  text-align: center;
}
.sansuo p:first-child {
  font-size: 60px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  text-align: center;
  margin-bottom: 40px;
}
.sansuoBg {
  width: 1238px;
  height: 966px;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/Frame%202219%402x.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 80px;
  position: relative;
}
.sansuoBg p:first-child {
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  padding-top: 412px;
}
.sansuoBg p:last-child {
  width: 568px;
  height: 160px;
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  line-height: 40px;
  margin: 0 auto;
}

.pcSchoolOne {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: absolute;
  bottom: 12px;
  left: 219px;
}
.pcSchoolTwo {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: absolute;
  right: 252px;
  top: 84px;
}
.pcSchoolThree {
  width: 248px;
  height: 302px;
  position: absolute;
  bottom: 177px;
  right: 36px;
}

@media screen and (max-width: 1680px) {
  div /deep/ .el-carousel__container {
    height: 656px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }

  .aboutImg{
    width: 636px;
    height: 123px;
    display: block;
    margin: 70px auto;
  }
  .aboutTop1{
    width: 543px;
    height: 350px;
  }
  .aboutTop2{
    width: 476px;
    height: 350px;
  }

  .aboutTop3{
    width: 626px;
    height: 350px;
  }

  .aboutBox{
    margin-top: 18px;
  }
  
  .leftImg{
    width: 359px;
    height: 718px;
  }

  .contentCenter{
    height: 718px;
    width: 1304px;
    margin-left: 18px;
  }
  .wenziCenter{
    width:918px;
    height: 350px;
  }

  .centerTop{
    height: 350px;
  }
  .centerTop img{
    width: 368px;
    height: 350px;
  }
  .wenziCenter p {
    width: 780px;
    font-size: 28px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    margin: 0 auto;
    line-height: 56px;
    text-align: center;
  }
  .centerTitle {
    font-size: 53px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    margin-top: 53px;
    margin-bottom: 35px;
    text-align: center;
  }

  .centerBtm{
    margin-top: 18px;
  }
  .centerBtm img:first-child {
    width: 482px;
    height: 350px;
  }
  .centerBtm img:last-child {
    width: 800px;
    height: 350px;
  }

  .shiming{
    width: 1120px;
    height: 264px;
  }

  .smTitle{
    font-size: 53px;
    padding-top: 35px;
    margin-bottom: 26px;
  }

  .shiming .phone{
    font-size: 28px;
    line-height: 56px;
  }

  .smBtm{
    height: 322px;
  }
  .smBox{
    width: 263px;
    height: 322px;
  }

  .smBox1 p:first-child, .smBox3 p:first-child{
    width: 221px;
    height: 175px;
    font-size: 18px;
    line-height: 44px;
  }

  .smBox1 p:last-child, .smBox3 p:last-child{
    font-size: 35px;
    margin-top: 52px;
  }
  .smBox2 p:first-child, .smBox4 p:first-child{
    font-size: 35px;
    margin-top: 21px;
    margin-bottom: 31px;
  }

  .smBox2 p:last-child, .smBox4 p:last-child{
    width: 221px;
    height: 220px;
    font-size: 18px;
    line-height: 44px;
  }

  .wlTitle img{
    width: 60px;
    height: 60px;
  }
  .wlTitle span{
    font-size: 35px;
  }
  .wlBox{
    width: 543px;
    height: 416px;
  }
  .wlContent{
    margin-top: 140px;
  }
  .wlContent p{
    text-align: center;
    font-size: 21px;
    line-height: 42px;
  }

  .jzTitle img{
    width: 60px;
    height: 60px;
  }
  .jzTitle span{
    font-size: 35px;
  }
  .jzContent{
    margin-top: 140px;
  }
  .jzContent p{
    text-align: center;
    font-size: 21px;
    line-height: 42px;
  }

  .fazhan{
    margin-top: 900px;
    /* height: 743px; */
  }
  /* .fzTitle{
    padding-top: 120px;
    font-size: 53px;
  }

  .fazhanP{
    margin: 35px auto;
  } */
}


@media screen and (max-width: 1440px) {
  div /deep/ .el-carousel__container {
    height: 563px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 563px;
  }
  .aboutImg {
    width: 545px;
    height: 105px;
    display: block;
    margin: 30px auto;
    margin-top: 50px;
  }
  .aboutUs {
    margin: 20px auto;
  }

  .aboutTop1 {
    width: 466px;
    height: 300px;
  }
  .aboutTop2 {
    width: 408px;
    height: 300px;
  }
  .aboutTop3 {
    width: 536px;
    height: 300px;
  }

  .leftImg {
    width: 315px;
    height: 615px;
  }

  .contentCenter {
    width: 1100px;
    height: 610px;
    margin-left: 0px;
  }

  .centerTop {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: space-between;
  }
  .centerTop img {
    width: 315px;
    height: 300px;
  }
  .wenziCenter {
    width: 770px;
    height: 300px;
    background: #ffe500;
  }
  .wenziCenter p {
    width: 650px;
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    margin: 0 auto;
    line-height: 48px;
    text-align: center;
  }
  .centerTitle {
    font-size: 45px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 20px;
    text-align: center;
  }
  .centerBtm {
    width: 100%;
    height: 400px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .centerBtm img:first-child {
    width: 405px;
    height: 300px;
  }
  .centerBtm img:last-child {
    width: 690px;
    height: 300px;
  }
  .sansuo {
    width: 85%;
    height: 400px;
    margin: 0 auto;
    margin-top: 50px;
  }

  .pcSchoolOne {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: absolute;
    bottom: 12px;
    left: 219px;
  }
  .pcSchoolTwo {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: absolute;
    right: 252px;
    top: 84px;
  }
  .pcSchoolThree {
    width: 248px;
    height: 302px;
    position: absolute;
    bottom: 177px;
    right: 36px;
  }
}
@media screen and (max-width: 1366px) {
  div /deep/ .el-carousel__container {
    height: 534px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 534px;
  }
  .aboutImg {
    width: 517px;
    height: 100px;
    display: block;
    margin: 30px auto;
    margin-top: 57px;
  }

  .aboutTop1 {
    width: 442px;
    height: 285px;
  }
  .aboutTop2 {
    width: 387px;
    height: 285px;
  }
  .aboutTop3 {
    width: 509px;
    height: 285px;
  }

  .leftImg {
    width: 292px;
    height: 583px;
  }

  .contentCenter {
    width: 1070px;
    height: 285px;
    margin-left: 0px;
  }

  .centerTop {
    width: 100%;
    height: 285px;
    display: flex;
    justify-content: space-between;
  }
  .centerTop img {
    width: 315px;
    height: 285px;
  }
  .wenziCenter {
    width: 747px;
    height: 285px;
    background: #ffe500;
  }
  .wenziCenter p {
    width: 640px;
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    margin: 0 auto;
    line-height: 48px;
    text-align: center;
  }
  .centerTitle {
    font-size: 43px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 20px;
    text-align: center;
  }
  .centerBtm {
    width: 100%;
    height: 285px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .centerBtm img:first-child {
    width: 384px;
    height: 285px;
  }
  .centerBtm img:last-child {
    width: 662px;
    height: 285px;
  }

  /* ---------------------------------------------------------------------- */

  /* 我们的使命 */
  .shiming {
    width: 910px;
    height: 215px;
    margin: 40px auto;
  }
  .smTitle {
    font-size: 43px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #975b00;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  .shiming .phone {
    width: 90%;
    font-size: 23px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #975b00;
    line-height: 46px;
    margin: 0 auto;
  }
  .pcSmBtm {
    display: none;
  }
  .smBtmPhone {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px;
  }
  .smBtmPhone .smBox {
    width: 213px;
    height: 262px;
  }
  .smBox1,
  .smBox2 {
    margin-bottom: 15px;
  }
  .smBox1 p:first-child,
  .smBox3 p:first-child {
    width: 136px;
    height: 107px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #252525;
    margin: 20px auto;
    line-height: 22px;
    text-align: left;
  }
  .smBox1 p:last-child,
  .smBox3 p:last-child {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #252525;
    text-align: center;
    margin-top: 35px;
  }
  .smBox2 p:first-child,
  .smBox4 p:first-child {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .smBox2 p:last-child,
  .smBox4 p:last-child {
    width: 136px;
    height: 107px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    text-align: left;
    margin: 0 auto;
  }
  /* 未来展望 */

  .weilai {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  .wlBox {
    width: 441px;
    height: 338px;
    background: #e3f0ff;
    border-radius: 40px 40px 40px 40px;
  }
  .jzBox {
    background: #e0ffe9;
  }
  .wlTitle {
    width: 400px;
    margin: 0 auto;
    margin-top: 78px;
    position: relative;
  }

  .wlTitle img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
  }
  .jzTitle {
    width: 517px;
    margin: 0 auto;
    margin-top: 78px;
    position: relative;
  }

  .jzTitle img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 80px;
    transform: translateY(-50%);
  }

  .wlTitle span {
    font-size: 28px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0075ff;
    position: absolute;
    right: 134px;
    top: 50%;
    transform: translateY(-50%);
  }
  .jzTitle span {
    font-size: 28px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #00af7b;
    position: absolute;
    right: 180px;
    top: 50%;
    transform: translateY(-50%);
  }

  .wlContent {
    width: 368px;
    margin: 40px auto;
    margin-top: 120px;
    text-align: center;
  }
  .wlContent p {
    font-size: 17px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #0075ff;
    line-height: 34px;
  }

  .jzContent {
    width: 368px;
    margin: 40px auto;
    margin-top: 120px;
    /* background: yellowgreen; */
  }
  .jzContent p {
    font-size: 17px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #00af7b;
    line-height: 34px;
    text-align: center;
  }

  /* ------------------------------------------------------------------------------------------------------------ */

  .fazhan {
    width: 100%;
    height: 874px;
    margin-top: 750px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/fazhanlicheng.png");
    background-size: 100% 100%;
    /* background: yellowgreen; */
  }
  .fzTitle {
    font-size: 43px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #975b00;
    text-align: center;
    padding-top: 130px;
  }
  .fazhanP {
    font-size: 11px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #975b00;
    margin: 40px auto;
    text-align: center;
  }

  .developBox {
    width: 910px;
    display: flex;
    margin: 0 auto;
    /* background: red; */
  }

  .deveItem {
    width: 182px;
  }

  .deveTop {
    font-size: 20px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    text-align: center;
    margin-top: 154px;
  }

  .devetopText,
  .deveBtmText {
    width: 100%;
    height: 128px;
    font-size: 11px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    margin: 15px 0;
  }

  .devetopImg {
    width: 28px;
    height: 28px;
    margin: 0 81px 20px 81px;
  }
  .huanImg {
    margin: 20px 81px;
  }

  .lineYellow {
    width: 100%;
    height: 20px;
    background: #ffe500;
    margin-top: 30px;
  }
  .lineGreen {
    width: 100%;
    height: 20px;
    background: #25ae85;
  }

  .deveBtm {
    font-size: 28px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    text-align: center;
    margin-top: 40px;
  }

  .rongyu {
    width: 1280px;
    margin: 0px auto;
    /* margin-top: -100px; */
    margin-top: 1200px;
  }

  .zhuoyu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
  }
  .xueshu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
  }
  .zyLeft {
    width: 440px;
    height: 435px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/rongyuchengjiuOne.png");
    background-size: 100% 100%;
  }
  .xueLeft {
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/rongyuchengjiuTwo.png");
  }
  .zyLeft p {
    width: 192px;
    font-size: 24px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #975b00;
    line-height: 48px;
    margin-left: 124px;
    text-align: center;
  }
  .xueLeft p {
    color: #00885f;
  }

  .zyLeTitle {
    font-size: 40px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #975b00;
    margin-top: 132px;
    margin-left: 110px;
    margin-bottom: 30px;
  }
  .xueLeTitle {
    color: #00885f;
  }
  .zyRight {
    width: 744px;
    height: 387px;
  }
  .zyRiTitle {
    font-size: 32px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #975b00;
    text-align: center;
    font-weight: bold;
  }
  .xueTitle {
    color: #00885f;
  }
  .zyRight p {
    width: 744px;
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    color: #975b00;
    line-height: 64px;
  }

  .xueRight p {
    color: #00885f;
    text-align: center;
  }
  .ganxie {
    width: 1280px;
    height: 370px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/rongyuchengjiuThree.png");
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 150px;
  }
  .ganxieContent {
    width: 1000px;
    margin: 0 auto;
    padding-top: 80px;
  }
  .ganxieContent p {
    font-size: 32px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #975b00;
    line-height: 64px;
    text-align: center;
  }

  .sansuo p:first-child {
    font-size: 43px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #975b00;
    text-align: center;
    margin-bottom: 40px;
  }
  .sansuoBg p:first-child {
    position: relative;
    top: -130px;
    font-size: 23px;
  }
  .sansuoBg p:last-child {
    width: 468px;
    height: 160px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #975b00;
    line-height: 40px;
    margin: 0 auto;
    margin-top: -150px;
  }
  .sansuo p {
    font-size: 23px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #975b00;
    text-align: center;
  }
  .sansuoBg {
    width: 881px;
    height: 688px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/Frame%202219%402x.png");
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 80px;
    position: relative;
  }

  .pcSchoolOne {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: absolute;
    bottom: 0px;
    left: 140px;
  }
  .pcSchoolTwo {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: absolute;
    right: 152px;
    top: 0px;
  }
  .pcSchoolThree {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: absolute;
    bottom: 80px;
    right: -20px;
  }
  /* 88888888 */

  .rongyu {
    width: 910px;
    margin: 0px auto;
    /* margin-top: -100px; */
    margin-top: 800px;
  }

  .zhuoyu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
  }
  .xueshu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
  }
  .zyLeft {
    width: 313px;
    height: 309px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/rongyuchengjiuOne.png");
    background-size: 100% 100%;
  }
  .xueLeft {
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/rongyuchengjiuTwo.png");
  }
  .zyLeft p {
    width: 137px;
    font-size: 17px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #975b00;
    line-height: 34px;
    margin-left: 88px;
    text-align: center;
  }
  .xueLeft p {
    color: #00885f;
  }

  .zyLeTitle {
    font-size: 28px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #975b00;
    margin-top: 94px;
    margin-left: 78px;
    margin-bottom: 21px;
  }
  .xueLeTitle {
    color: #00885f;
  }
  .zyRight {
    width: 529px;
    height: 275px;
  }
  .zyRiTitle {
    font-size: 23px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #975b00;
    text-align: center;
    font-weight: bold;
  }
  .xueTitle {
    color: #00885f;
  }
  .zyRight p {
    width: 529px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    color: #975b00;
    line-height: 32px;
  }

  .xueRight p {
    color: #00885f;
    text-align: center;
  }
  .ganxie {
    width: 770px;
    height: 265px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/aboutus/rongyuchengjiuThree.png");
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 150px;
  }
  .ganxieContent {
    width: 615px;
    margin: 0 auto;
    padding-top: 45px;
  }
  .ganxieContent p {
    font-size: 23px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #975b00;
    line-height: 46px;
    text-align: center;
  }
}
@media screen and (max-width: 1280px) {
  div /deep/ .el-carousel__container {
    height: 500px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 500px;
  }
  .aboutImg {
    width: 485px;
    height: 100px;
    display: block;
    margin: 30px auto;
    margin-top: 57px;
  }

  .aboutTop1 {
    width: 414px;
    height: 267px;
  }
  .aboutTop2 {
    width: 363px;
    height: 267px;
  }
  .aboutTop3 {
    width: 477px;
    height: 267px;
  }

  .leftImg {
    width: 273px;
    height: 547px;
  }

  .contentCenter {
    width: 990px;
    height: 285px;
    margin-left: 0px;
  }

  .centerTop {
    width: 100%;
    height: 267px;
    display: flex;
    justify-content: space-between;
  }
  .centerTop img {
    width: 280px;
    height: 267px;
  }
  .wenziCenter {
    width: 700px;
    height: 267px;
    background: #ffe500;
  }
  .wenziCenter p {
    width: 600px;
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    margin: 0 auto;
    line-height: 48px;
    text-align: center;
  }
  .centerTitle {
    font-size: 40px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
  }
  .centerBtm {
    width: 100%;
    height: 267px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .centerBtm img:first-child {
    width: 360px;
    height: 267px;
  }
  .centerBtm img:last-child {
    width: 620px;
    height: 267px;
  }
}
@media screen and (max-width: 750px) {
  div /deep/ .el-carousel__container {
    height: 146px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 146px;
  }
  .about {
    max-width: 750px;
    overflow: hidden;
  }
  .aboutUs {
    display: none;
  }
  .phoneIntroduction {
    display: block;
    margin-bottom: 10px;
  }
  .phoneAboutus {
    display: block;
  }

  /* 我们的使命 */
  .shiming {
    width: 90%;
    height: 147px;
    margin: 0 auto;
  }
  .smTitle {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #975b00;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  .shiming .phone {
    width: 90%;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #975b00;
    line-height: 22px;
    margin: 0 auto;
  }
  .pcSmBtm {
    display: none;
  }
  .smBtmPhone {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 24px;
  }
  .smBtmPhone .smBox {
    width: 48%;
    height: 200px;
  }
  .smBox1,
  .smBox2 {
    margin-bottom: 15px;
  }
  .smBox1 p:first-child,
  .smBox3 p:first-child {
    width: 136px;
    height: 107px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #252525;
    margin: 20px auto;
    line-height: 22px;
    text-align: left;
  }
  .smBox1 p:last-child,
  .smBox3 p:last-child {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #252525;
    text-align: center;
    margin-top: 35px;
  }
  .smBox2 p:first-child,
  .smBox4 p:first-child {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .smBox2 p:last-child,
  .smBox4 p:last-child {
    width: 136px;
    height: 107px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    text-align: left;
    margin: 0 auto;
  }
  /* 未来展望 */
  .weilai {
    width: 100%;
    margin: 0 auto;
    display: initial;
  }
  .wlBox {
    width: 100%;
    height: 230px;
    margin-top: 16px;
  }
  .wlTitle {
    width: 100%;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    box-sizing: border-box;
  }
  .wlTitle img {
    width: 28px;
    height: 28px;
    position: initial;
    transform: none;
    margin-right: 20px;
  }
  .wlTitle span {
    position: initial;
    transform: none;
    font-size: 24px;
  }

  .wlContent {
    width: 100%;
    margin: 0;
  }
  .wlContent p {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #0075ff;
    line-height: 22px;
    text-align: center;
  }

  .jzTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    padding: 25px 0;
    box-sizing: border-box;
  }
  .jzTitle img {
    width: 28px;
    height: 28px;
    position: initial;
    transform: none;
    margin-right: 20px;
  }
  .jzTitle span {
    position: initial;
    font-size: 24px;
    transform: none;
  }

  .jzContent {
    width: 100%;
    margin-top: 0;
  }
  .jzContent p {
    font-size: 14px;
    line-height: 22px;
    padding: 0 25px;
    box-sizing: border-box;
    text-align: center;
  }

  /* 发展历程 */
  .development {
    display: none;
  }
  .phoneDevelopment {
    display: block;
  }
  .fazhan {
    height: 460px;
    margin-top: 906px;
    padding: 0 16px;
    box-sizing: border-box;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/phoneAboutus/phoneAboutUsfzlc.png");
  }
  .fazhan .fzTitle {
    padding-top: 60px;
    font-size: 32px;
    box-sizing: border-box;
  }
  .fazhan .fazhanP {
    margin-top: 24px;
    font-size: 14px;
    text-align: left;
    margin-bottom: 24px;
  }
  .developmentTwo {
    display: flex;
  }
  .developBox {
    display: inherit;
    width: 100%;
  }
  .developBox {
    /* background: red; */
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .deveItem {
    width: 65px;
  }
  .lineYellow {
    width: 65px;
    height: 10px;
    background: #ffe500;
    margin-top: 10px;
  }
  .lineGreen {
    width: 65px;
    height: 10px;
    background: #25ae85;
    margin-top: 10px;
  }
  .deveTop {
    width: 65px;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    text-align: center;
    margin-top: 0px;
  }

  .deveBtmText {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .deveBtmText .titleDeve {
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    margin-bottom: 10px;
  }
  .deveBtmText {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    line-height: 22px;
  }
  .devetopImg {
    width: 24px;
    height: 24px;
    margin: 0 22px 0px 22px;
  }

  /* 荣誉 */
  .rongyu {
    width: 100%;
    margin-top: 0px;
    padding: 0 16px;
    box-sizing: border-box;
  }
  .rongyu .fzTitle {
    font-size: 32px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #975b00;
    padding-top: 0;
  }
  .rongyu .fazhanP {
    margin-top: 24px;
    font-size: 14px;
    text-align: center;
    margin-bottom: 24px;
  }
  .PhoneRy {
    display: block;
  }
  .pcRy {
    display: none;
  }
  .zhuoyu {
    display: initial;
  }
  .zyLeft {
    width: 239px;
    height: 202px;
    margin: 0 auto;
    text-align: center;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/phoneAboutus/phoneAboutUsRy.png");
    padding-top: 60px;
    box-sizing: border-box;
  }
  .zyLeTitle {
    width: 163px;
    margin: 0;
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #975b00;
    margin: 0 auto;
  }
  .zyLeft p {
    width: 163px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #975b00;
    margin: 0;
    line-height: 24px;
    margin: 0 auto;
    margin-top: 12px;
  }
  .zyRight {
    width: 100%;
    margin-top: 20px;
    height: auto;
  }
  .zyRiTitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .zyRight p {
    width: 100%;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 10px;
  }
  /* https://lwjy.oss-cn-beijing.aliyuncs.com/school/phoneAboutus/phoneAboutUshzdcj.png */
  .xueshu {
    display: initial;
    margin-top: 32px;
  }
  .xueLeft {
    width: 273px;
    height: 239px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/phoneAboutus/phoneAboutUshzdcj.png");
  }

  .ganxie {
    width: 100%;
    height: 195px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/phoneAboutus/phoneAboutUsGx.png");
    margin-top: 32px;
  }
  .ganxie .ganxieContent {
    width: 70%;
    padding: 0;
    margin: 0 auto;
    padding-top: 26px;
    box-sizing: border-box;
  }
  .ganxieContent p {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #975b00;
    line-height: 24px;
  }

  /* ----------------------------------- */
  .sansuo {
    display: none;
  }
  .sansuoPhone {
    display: block;
    width: 100%;
    /* height: 200px; */
    margin: 0 auto;
  }
  .sansuoPhone p:first-child {
    width: 352px;
    font-size: 32px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #975b00;
    margin: 0 auto;
    text-align: center;
  }
  .sansuoPhone p:nth-child(2) {
    width: 308px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #975b00;
    text-align: center;
    margin: 20px auto;
  }
  .sansuoPhone img {
    width: 354px;
    height: 340px;
    margin: 0 auto;
  }

  .sansuoGreen {
    width: 100%;
    background: #25ae85;
    margin-bottom: 20px;
    margin-top: 15px;
    padding: 15px 0;
  }
  .sansuoGreen p:first-child {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
  .sansuoGreen p:last-child {
    width: 342px;
    height: 112px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
  }

  .phoneSchoolImg {
    width: 354px;
    height: 340px;
    margin: 0 auto;
    position: relative;
  }
  .phoneSchoolOne {
    width: 94px;
    height: 115px;
    /* background-color: red; */
    position: absolute;
    bottom: 4px;
    left: 34px;
  }
  .phoneSchoolTwo {
    width: 89px;
    height: 89px;
    border-radius: 50%;
    /* background-color: yellow; */
    position: absolute;
    right: 17px;
    top: 66px;
  }
  .phoneSchoolThree {
    width: 108px;
    height: 108px;
    border-radius: 50%;
    /* background-color: green; */
    position: absolute;
    right: 51px;
    bottom: 32px;
  }
}
</style>
